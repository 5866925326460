import React, { FC } from 'react';

import { DefaultPropsInputFile } from '../type';

import { ReactComponent as AddCloud } from '../../../../assets/svg/else/add-cloud.svg';

import styles from '../styles.module.scss';
import style from './style.module.scss';

const SelectFile: FC<DefaultPropsInputFile> = ({
  setValue,
  disabled,
  text,
}) => {
  return (
    <div className={[styles.container, style.container].join(' ')}>
      <label className={style.box}>
        <input
          type="file"
          accept=".xlsx"
          onChange={setValue}
          disabled={disabled}
        />
        <div className={[style.AddCloud, style.TextButton].join(' ')}>
          <AddCloud />
          <span>{text}</span>
        </div>
      </label>
    </div>
  );
};

export default SelectFile;
