import React, { FC } from 'react';

import style from './style.module.scss';
import styles from '../styles.module.scss';

import { ReactComponent as CheckIcon } from '../../../../assets/svg/done/check.svg';
import { DefaultPropsInputCheck } from '../type';

const Check: FC<DefaultPropsInputCheck> = ({
  name,
  value,
  title,
  checked,
  setValue,
  disabled,
}) => {
  return (
    <label
      className={
        [disabled && styles.disabled, style.box].join(' ') || style.box
      }
    >
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        name={name}
        value={value}
        onChange={setValue}
      />
      <span className={style.checkmate}>{checked && <CheckIcon />}</span>
      <span className={style.title}>{title}</span>
    </label>
  );
};

export default Check;
