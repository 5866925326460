import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Navbar from '../../components/module/Navbar';

import { privateRoutes } from './admin';

import style from './style.module.scss';

const Application = () => {
  return (
    <div className={style.auth}>
      <Navbar />
      <div className={style.boxContent}>
        <Suspense>
          <Routes>
            {privateRoutes.map(route => (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))}
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};

export default Application;
