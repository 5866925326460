import React, { FC, useCallback } from 'react';

import { ReactComponent as Cancel } from '../../../../assets/svg/else/cancel.svg';
import Earth from '../../../../assets/img/iconsRound/earth.png';

import style from './style.module.scss';

import { useModal } from '../../../../app/context/modal';
import { Modal } from '../../../../app/context/modal/type';

const IconModal: FC<Modal> = ({ Content, props, modals }) => {
  const modal = useModal();

  const closeMenu = useCallback(() => {
    modal?.unSetModal();
  }, []);

  return (
    <div
      className={[
        style.container,
        modals?.size ? style[modals.size] : style.medium,
      ].join(' ')}
      onClick={event => event.stopPropagation()}
    >
      <div className={style.header}>
        <div className={style.cancel}>
          <button onClick={closeMenu}>
            <Cancel />
          </button>
        </div>
        <div className={style.title}>
          <div className={style.imgIcon}>
            <img src={modals?.icon || Earth} alt="earth" />
          </div>
          <div>
            {modals?.title && <h3>{modals.title}</h3>}
            {modals?.description && <p>{modals.description}</p>}
          </div>
        </div>
      </div>
      <div className={style.content}>
        <Content {...props} />
      </div>
      {modals?.note && <p className={style.note}>{modals.note}</p>}
    </div>
  );
};

export default IconModal;
