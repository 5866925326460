import Service from '../../../../utils/interceptors';

import { setCookie } from '../../../../utils/cookies';
import { UserRegister } from '../../../../app/context/auth/type';

class Authentication {
  async getCSRF() {
    return null;
  }

  async register(data: UserRegister) {
    return Service.post('user/register', data)
      .then(res => {
        setCookie('token', res.data.token, 10);
        return res;
      })
      .catch(err => err);
  }

  async login(data: { email: string; password: string }) {
    return await Service.post('user/login', data)
      .then(res => {
        setCookie('token', res.data.token, 10);
        return res;
      })
      .catch(err => err);
  }
}

export default new Authentication();
