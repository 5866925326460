import React, { lazy } from 'react';

const Business = lazy(() => import('./Business'));
const Settings = lazy(() => import('./Settings'));
const Oops = lazy(() => import('../../../components/statusRes/Oops'));
const APPLICATION = lazy(() => import('../app'));

const Routes = {
  HOME: '/*',
  SERVICE: '/service',
  SETTINGS: '/settings/*',
  APPLICATION: '/application/*',
  ELSE: '*',
};

export const privateRoutes = [
  { path: Routes.HOME, element: <Business /> },
  { path: Routes.SETTINGS, element: <Settings /> },
  { path: Routes.APPLICATION, element: <APPLICATION /> },
  {
    path: Routes.SERVICE,
    element: <Oops />,
  },
  {
    path: Routes.ELSE,
    element: <Oops />,
  },
];
