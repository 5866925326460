import React, { useEffect } from 'react';

import useAlert from './app/hooks/useAlert';

import { AlertType } from './app/context/alert/type';
import { useAuthState } from './app/context/auth';
import { useMenuState } from './app/context/menu';
import {
  useLanguageDispatch,
  SetLanguage,
  useLanguageState,
} from './app/context/language';

import AppRouter from './screen';

import style from './style.module.scss';

const App = () => {
  const { error, user } = useAuthState();
  const { open } = useMenuState();
  const dispatch = useLanguageDispatch();
  const initState = useLanguageState();
  const alert = useAlert();

  useEffect(() => {
    if (error)
      alert?.addAlert({
        message: String(error),
        type: AlertType.error,
      });

    if (user) {
      SetLanguage(dispatch, user.language, initState);
      console.log(user, initState);
    }
  }, [error, user]);

  return (
    <div className={!open ? style.app : [style.app, style.modal].join(' ')}>
      <AppRouter />
    </div>
  );
};

export default App;
