import Authentication from '../../../screen/unknown/authentication/api/Authentication';
import Profile from '../../../screen/auth/admin/api/Profile';

import { deleteCookie } from '../../../utils/cookies';

import { StatusReq } from '../../type';
import { initState, UserLogin, UserRegister } from './type';

import CleatErrorContext from '../../hooks/CleatErrorContext';
import { Redirect } from '../../../utils/interceptors';

class Thunk {
  async doLogin(dispatch: any, user: UserLogin) {
    try {
      dispatch({ status: StatusReq.pending });

      const result = await Authentication.login(user);

      dispatch({
        status: StatusReq.resolved,
        token: result,
      });
      Redirect();
    } catch (error) {
      dispatch({ status: StatusReq.rejected, error });
      CleatErrorContext(dispatch, { status: StatusReq.idle, error: null });
    }
  }

  async doRegister(dispatch: any, user: UserRegister) {
    try {
      dispatch({ status: StatusReq.pending });
      const result = await Authentication.register(user);

      dispatch({
        status: StatusReq.resolved,
        token: result,
        error: null,
      });
      Redirect();
    } catch (error) {
      dispatch({ status: StatusReq.rejected, error });
      CleatErrorContext(dispatch, { status: StatusReq.idle, error: null });
    }
  }

  async doLogout(dispatch: any, initialState: initState) {
    deleteCookie('token');
    dispatch(initialState);
    Redirect();
  }

  async doInfoUser(dispatch: any) {
    try {
      dispatch({ status: StatusReq.pending });

      const result = await Profile.getInfoUser();

      dispatch({
        status: StatusReq.resolved,
        user: result.data,
        error: null,
      });
    } catch (error) {
      dispatch({ status: StatusReq.rejected, error });
      CleatErrorContext(dispatch, { status: StatusReq.idle, error: null });
    }
  }
}

export default new Thunk();
