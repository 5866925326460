import { useContext, useReducer, createContext, FC, ReactNode } from 'react';

import Thunk from './reducer';

import { StatusReq } from '../../type';

import { DoDispatch, DoLoginType, DoRegisterType, initState } from './type';

const AuthStateContext = createContext<initState | null>(null);
const AuthDispatchContext = createContext<any>(null);

const reducer = (currentState: initState, newState: initState) => ({
  ...currentState,
  ...newState,
});

const useAuthState = () => {
  const context = useContext(AuthStateContext);
  if (!context) throw new Error('useAuthState must be used in AuthProvider');

  return context;
};

const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (!context) throw new Error('useAuthDispatch must be used in AuthProvider');

  return context;
};

const initialState: initState = {
  status: StatusReq.idle,
  auth: false,
  user: null,
  token: null,
  error: null,
};

type Props = {
  children: ReactNode;
};

const AuthProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const doLogin: DoLoginType = async (dispatch, user) =>
  Thunk.doLogin(dispatch, user);

const doLogout: DoDispatch = async dispatch => {
  Thunk.doLogout(dispatch, initialState);
};

const doRegister: DoRegisterType = async (dispatch, user) => {
  Thunk.doRegister(dispatch, user);
};
const doInfoUser: DoDispatch = async dispatch => {
  Thunk.doInfoUser(dispatch);
};

export {
  AuthProvider,
  useAuthState,
  useAuthDispatch,
  doLogin,
  doLogout,
  doRegister,
  doInfoUser,
};
