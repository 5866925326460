import React from 'react';
import { NavLink } from 'react-router-dom';

const Login = React.lazy(() => import('./authentication/Login'));
const Registration = React.lazy(() => import('./authentication/Registration'));
const Quiz = React.lazy(() => import('./quiz'));
const Oops = React.lazy(() => import('../../components/statusRes/Oops'));

const Routes = {
  HOME: '/',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  QUIZ: '/quiz',
};

export const publicRoutes = [
  {
    path: Routes.HOME,
    element: <Quiz />,
  },
  {
    path: Routes.LOGIN,
    element: <Login />,
  },
  {
    path: Routes.REGISTRATION,
    element: <Registration />,
  },
  {
    path: Routes.QUIZ,
    element: <Quiz />,
  },
  {
    path: '*',
    element: <Oops />,
  },
];
