import React, { useCallback, useState } from 'react';

import { useMenuDispatch, useVisible } from '../../../app/context/menu';

import style from './style.module.scss';

import logo from '../../../assets/img/logo/logo.png';
import { ReactComponent as Menu } from '../../../assets/svg/else/menu.svg';
import { ReactComponent as Notification } from '../../../assets/svg/notification/notification.svg';
import { ReactComponent as NotificationActive } from '../../../assets/svg/notification/notification-active.svg';

import UserName from './components/UserName';

const Navbar = () => {
  const [notification, setNotification] = useState(false);
  const menuDispatch = useMenuDispatch();

  const openMenu = useCallback(() => {
    useVisible(menuDispatch, true);
  }, []);

  return (
    <div className={style.container}>
      <div className={style.boxLogoMenu}>
        <div className={style.boxLogo}>
          <img src={logo} alt="logo" />
        </div>
        <button onClick={openMenu}>
          <Menu />
        </button>
      </div>
      <div className={style.infoProfile}>
        <button>
          {notification ? <NotificationActive /> : <Notification />}
        </button>
        <div className={style.boxUser}>
          <UserName />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
