import React, { FC } from 'react';

import style from './style.module.scss';
import styles from '../styles.module.scss';

import { DefaultPropsInputRadio } from '../type';

const RadioBox: FC<DefaultPropsInputRadio> = ({
  name,
  value,
  title,
  checked,
  setValue,
  disabled,
}) => {
  return (
    <div className={styles.container}>
      <label
        className={
          [
            disabled && styles.disabled,
            checked && style.active,
            style.box,
          ].join(' ') || style.box
        }
      >
        <input
          type="radio"
          checked={checked}
          disabled={disabled}
          name={name}
          value={value}
          onChange={!disabled ? setValue : () => null}
        />
        <span>{title}</span>
      </label>
    </div>
  );
};

export default RadioBox;
