import React, { FC } from 'react';

import { useAuthState } from '../../../../../app/context/auth';
import { StatusReq } from '../../../../../app/type';

import Loader from '../../../../module/Loader';

import style from './style.module.scss';

type Props = {
  className?: string;
};

const defaultProps: Props = {
  className: '',
};

const UserName: FC<Props> = ({ className }) => {
  const { user, status } = useAuthState();

  if (status === StatusReq.pending) {
    return (
      <div className={style.boxName}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={[className, style.boxName].join(' ')}>
      {user?.firstName} {user?.lastName}
    </div>
  );
};

UserName.defaultProps = defaultProps;

export default UserName;
