import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { doInfoUser, useAuthDispatch, useAuthState } from '../app/context/auth';

import { getCookie } from '../utils/cookies';
import { publicRoutes } from './unknown';

import Application from './auth';

const AppRouter = () => {
  const { token } = useAuthState();
  const [auth, setAuth] = useState(getCookie('token'));
  const authDispatch = useAuthDispatch();

  useEffect(() => {
    doInfoUser(authDispatch);
  }, []);

  useEffect(() => {
    if (!auth) setAuth(token ? token : undefined);
  }, [token]);

  return auth ? (
    <Application />
  ) : (
    <Suspense>
      <Routes>
        {publicRoutes.map(route => (
          <Route path={route.path} element={route.element} key={route.path} />
        ))}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
