import React, { ChangeEvent, FC, useState } from 'react';

import { DefaultPropsInputPassword } from '../type';

import styles from '../styles.module.scss';

import { ReactComponent as Eye } from '../../../../assets/svg/eye/eye.svg';
import { ReactComponent as EyeSlash } from '../../../../assets/svg/eye/eye-slash.svg';

const Password: FC<DefaultPropsInputPassword> = ({
  placeholder,
  value,
  setValue,
  disabled,
  password = false,
  title = null,
  necessary,
  error,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(password);
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <div className={[styles.container, title && styles.withTitle].join(' ')}>
      {title && (
        <span className={styles.title}>
          {title} {necessary && <span className={styles.necessary}>*</span>}
        </span>
      )}
      <label
        className={
          [
            disabled && styles.disabled,
            focus && styles.active,
            error && styles.error,
            styles.box,
          ].join(' ') || styles.box
        }
      >
        <input
          className={styles.input}
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e)}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />

        {showPassword ? (
          <span
            onClick={() => setShowPassword(false)}
            className={styles.togglePassword}
          >
            <Eye />
          </span>
        ) : (
          <span
            onClick={() => setShowPassword(true)}
            className={styles.togglePassword}
          >
            <EyeSlash />
          </span>
        )}
      </label>
      {error && <p className={styles.descriptionError}>{error}</p>}
    </div>
  );
};

export default Password;
