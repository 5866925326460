import React, { ChangeEvent, FC } from 'react';

import { DefaultPropsInputPhoto } from '../type';

import photoUrl from '../../../../assets/img/default/photoProfile.png';

import { ReactComponent as Img } from '../../../../assets/svg/else/img.svg';

import styles from '../styles.module.scss';
import style from './style.module.scss';

const SelectPhoto: FC<DefaultPropsInputPhoto> = ({
  value,
  setValue,
  disabled,
  img,
  alt,
}) => {
  const imgVisible = img ? img : photoUrl;
  const altVisible = alt ? alt : 'defaultPhoto';

  return (
    <div className={styles.container}>
      <label className={style.box}>
        <input
          type="file"
          accept=".png, .jpeg, .jpg"
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e)}
          disabled={disabled}
        />
        <img src={imgVisible} alt={altVisible} />
        <span className={style.blur}>
          <Img />
        </span>
      </label>
    </div>
  );
};

export default SelectPhoto;
