import React, { ChangeEvent, FC, useState } from 'react';

import { DefaultPropsInputNumber } from '../type';

import { ReactComponent as Error } from '../../../../assets/svg/status/error.svg';

import styles from '../styles.module.scss';

const Number: FC<DefaultPropsInputNumber> = ({
  placeholder,
  value,
  setValue,
  disabled,
  title = null,
  necessary,
  error,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <div className={[styles.container, title && styles.withTitle].join(' ')}>
      {title && (
        <span className={styles.title}>
          {title} {necessary && <span className={styles.necessary}>*</span>}
        </span>
      )}
      <label
        className={
          [
            focus && styles.active,
            disabled && styles.disabled,
            error && styles.error,
            error && styles.box,
          ].join(' ') || ''
        }
      >
        <input
          className={styles.input}
          type="number"
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e)}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {error && <span className={styles.flexCenter}>{<Error />}</span>}
      </label>
      {error && <p className={styles.descriptionError}>{error}</p>}
    </div>
  );
};

export default Number;
