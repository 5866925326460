import Service from '../../../../utils/interceptors';

class Profile {
  async getInfoUser() {
    return await Service.get('user/info')
      .then(res => {
        return res;
      })
      .catch(err => err);
  }
}

export default new Profile();
