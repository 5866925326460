import React, { FC, useEffect, useState } from 'react';

import { DefaultPropsISelect } from '../../type';

import { ReactComponent as Error } from '../../../../../assets/svg/status/error.svg';

import style from './style.module.scss';
import styles from '../../styles.module.scss';

const Select: FC<DefaultPropsISelect> = ({
  value,
  setValue,
  placeholder,
  disabled,
  title = null,
  Img,
  onClickImg,
  necessary,
  errorMessage,
  error,
  className,
}) => {
  const [errorIs, setError] = useState<boolean>(error || false);

  const blurError = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    event.preventDefault();

    if (necessary) {
      setError(!value ? true : false);
    }

    setValue(false);
  };

  useEffect(() => {
    if (!value && error) {
      return setError(true);
    }

    setError(false);
  }, [error, value]);

  return (
    <div
      className={[
        styles.container,
        title && styles.withTitle,
        className && className,
      ].join(' ')}
    >
      {title && (
        <span className={styles.title}>
          {title} {necessary && <span className={styles.necessary}>*</span>}
        </span>
      )}
      <label
        className={
          [
            disabled && styles.disabled,
            style.box,
            errorIs && styles.error,
          ].join(' ') || ''
        }
        onClick={blurError}
      >
        <span>{value ? value : placeholder}</span>
        {Img && (
          <button
            className={style.icon}
            onClick={onClickImg}
            disabled={disabled}
          >
            <Img />
          </button>
        )}
        {errorIs && <span className={styles.flexCenter}>{<Error />}</span>}
      </label>
      {errorIs && <p className={styles.descriptionError}>{errorMessage}</p>}
    </div>
  );
};

export default Select;
