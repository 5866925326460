import React from 'react';
import ReactDOM from 'react-dom/client';

import { HashRouter as Router } from 'react-router-dom';

import AlertProvider from './app/context/alert';
import { ModalProvider } from './app/context/modal';
import { AuthProvider } from './app/context/auth';
import { MenuProvider } from './app/context/menu';
import { LanguageProvider } from './app/context/language';

import './assets/style/index.scss';
import './assets/style/colors.scss';

import App from './App';
import Alerts from './components/module/Alert';
import Menu from './components/module/Menu';
import Modal from './components/module/Modal';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.Fragment>
    <Router basename="/">
      <LanguageProvider>
        <AuthProvider>
          <ModalProvider>
            <MenuProvider>
              <AlertProvider>
                <Menu />
                <Modal />
                <App />
                <Alerts />
              </AlertProvider>
            </MenuProvider>
          </ModalProvider>
        </AuthProvider>
      </LanguageProvider>
    </Router>
  </React.Fragment>,
);
